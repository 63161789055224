import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Line } from "react-chartjs-2";
import * as classes from "../../../styles/calculator/calculator.module.scss";
import "../../../styles/calculator/style.scss";
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const labels = ["Year 1", "Year 2", "Year 3", "Year 4", "Year 5"];

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";
          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += `${"$" + context.parsed.y}`;
          }
          return label;
        },
      },
    },
  },
  scales: {
    y: {
      ticks: {
        callback: function (value, index, ticks) {
          return "$" + value;
        },
      },
    },
  },
};

const Graph = ({ tableVal }) => {
  let nf = new Intl.NumberFormat("en-US");
  const data = {
    labels,
    datasets: [
      {
        type: "line",
        label: "Before Lucidity",
        borderColor: "rgb(255, 255, 255)",
        borderWidth: 1,
        fill: false,
        data: tableVal?.projectSpend,
      },
      {
        type: "line",
        label: "After Lucidity",
        borderColor: "rgb(221, 255, 85, 1)",
        backgroundColor: "rgb(221, 255, 85, 1)",
        borderWidth: 1,
        fill: true,
        data: tableVal?.spendPostLucidity,
      },
    ],
  };

  return (
    <>
    <Row>
      <Col sm={6}>
        <div className={`${classes.left_graph}`}>
          <span className={classes.graph_heading}>
            Storage Costs extrapolated over the next 5 years
          </span>
          <div className={classes.main_wrapper}>
            <div className={classes.whole_graph}>
              <div className={classes.title}>
                <div className={classes.before}>
                  <div className={classes.before__circle}></div>
                  <span>Before Lucidity</span>
                </div>
                <div className={classes.after}>
                  <div className={classes.after__circle}></div>
                  <span>After Lucidity</span>
                </div>
              </div>
              <Line options={options} data={data} />
            </div>
          </div>
        </div>
      </Col>
      <Col sm={6}>
        <div className={`right_table`}>
          <Table>
            <thead>
              <tr>
                <th>Annual</th>
                <th>Year 1</th>
                <th>Year 2</th>
                <th>Year 3</th>
                <th>Year 4</th>
                <th>Year 5</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Projected disk spend</td>
                <td>${nf.format(tableVal?.projectSpend[0])}</td>
                <td>${nf.format(tableVal?.projectSpend[1])}</td>
                <td>${nf.format(tableVal?.projectSpend[2])}</td>
                <td>${nf.format(tableVal?.projectSpend[3])}</td>
                <td>${nf.format(tableVal?.projectSpend[4])}</td>
              </tr>
              <tr>
                <td>Disk spend post Lucidity</td>
                <td>${nf.format(tableVal?.spendPostLucidity[0])}</td>
                <td>${nf.format(tableVal?.spendPostLucidity[1])}</td>
                <td>${nf.format(tableVal?.spendPostLucidity[2])}</td>
                <td>${nf.format(tableVal?.spendPostLucidity[3])}</td>
                <td>${nf.format(tableVal?.spendPostLucidity[4])}</td>
              </tr>
              <tr>
                <td style={{'white-space': 'pre'}}>Annual savings inc. <br></br>Lucidity charges</td>
                <td>${nf.format(tableVal?.savings[0])}</td>
                <td>${nf.format(tableVal?.savings[1])}</td>
                <td>${nf.format(tableVal?.savings[2])}</td>
                <td>${nf.format(tableVal?.savings[3])}</td>
                <td>${nf.format(tableVal?.savings[4])}</td>
              </tr>
              <tr>
                <td>Cumulative savings</td>
                <td>${nf.format(tableVal?.cSavings[0])}</td>
                <td>${nf.format(tableVal?.cSavings[1])}</td>
                <td>${nf.format(tableVal?.cSavings[2])}</td>
                <td>${nf.format(tableVal?.cSavings[3])}</td>
                <td>${nf.format(tableVal?.cSavings[4])}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
    </>
  );
};

export default Graph;
