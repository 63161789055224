import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Tick from "../../assets/images/audit/tick.svg";
import * as classes from "../../styles/calculator/calculator.module.scss";

const Hero = () => {
  return (
    <section className={classes.calculator}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} xs={12} className="text-center p-0">
            <div
              className={`${classes["hero__heading"]} justify-content-center`}
            >
              <h1>
                The Cloud Storage <span>savings</span> to <span>win</span> you
                over!
              </h1>
            </div>
            <div className={classes.hero__suheading}>
              <span>
                See it to believe it. Calculate your storage savings with
                Lucidity Auto-Scaler, the industry-leader in multi-cloud block
                storage orchestration
              </span>
            </div>
            <div
              className={`${classes["hero__features"]} justify-content-center`}
            >
              <div className={classes.tick}>
                <img src={Tick} alt="tick" />
                <span>Save on your storage costs</span>
              </div>

              <div className={classes.tick}>
                <img src={Tick} alt="tick" />
                <span>No Downtime</span>
              </div>
              <div className={classes.tick}>
                <img src={Tick} alt="tick" />
                <span>Free your DevOps</span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
