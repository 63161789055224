import React from "react";
import { Link } from "gatsby";
import { Container, Row, Button, Col } from "react-bootstrap";
import Layout from "../../components/Common/Layout";
import Seo from "../../components/Common/Seo";
import Hero from "../../components/Calculator/Hero";
import CalculateContainer from "../../components/Calculator/calculate";
import * as classes from "../../styles/calculator/calculator.module.scss";
import DemoLargeButton from "../../components/Common/DemoLargeButton";

const Calculator = () => {
  return (
    <Layout btnLink="/book-demo/audit">
      <Seo
        title="ROI Calculator | Calculate your Cloud Storage savings"
        description={`Calculate your savings potential and see how much can you optimize, with Lucidity AutoScaler`}
        canonicalUrl="https://www.lucidity.cloud/calculator"
      />
      <Hero />
      
      <CalculateContainer />

      <section className={classes.calculator__convinced}>
        <Container>
          <Row className="justify-content-center text-center">
            <Col sm={7} className={classes.heading}>
              <h2>
                Still not
                <span> convinced?</span>
              </h2>
            </Col>
          </Row>
          <Row className={classes.row}>
            <Col sm={6} className={classes.col_one}>
              <div className={classes.values}>
                <div className={classes.values__wrapper}>
                  <div className={classes.heading}>
                    <h2>
                      <span>Find out</span> how
                    </h2>
                  </div>
                  <div className={classes.leaders}>
                    <span>
                      Use our Audit tool to understand your spends and identify
                      where you waste
                    </span>
                  </div>
                  <div className={classes.buttons}>
                    <Link to="/audit">
                      <Button
                        variant="outlined"
                        className={classes["get_started"]}
                      >
                        Get started for free
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6} className={classes.col_two}>
              <div className={classes.values}>
                <div className={classes.values__wrapper}>
                  <div className={classes.heading}>
                    <h2>
                      Or
                      <span> let us show</span> you
                    </h2>
                  </div>
                  <div className={classes.leaders}>
                    <span>
                      How we helped Fortune 500 enterprises reduce almost 70% of
                      their cloud storage costs
                    </span>
                  </div>
                  <div className={classes.buttons_two}>
                    <DemoLargeButton />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Calculator;
