// extracted by mini-css-extract-plugin
export var action_btns = "calculator-module--action_btns--2woYq";
export var active = "calculator-module--active--jlc8k";
export var after = "calculator-module--after--K9W9Q";
export var after__circle = "calculator-module--after__circle--Pa23W";
export var before = "calculator-module--before--NjGTA";
export var before__circle = "calculator-module--before__circle--ZpULh";
export var before_after = "calculator-module--before_after--r62jA";
export var before_after__blur = "calculator-module--before_after__blur--R360P";
export var before_after__blur__hover = "calculator-module--before_after__blur__hover--mLUYE";
export var before_after__hover = "calculator-module--before_after__hover--8jwqo";
export var bottom = "calculator-module--bottom--Innrn";
export var buttons = "calculator-module--buttons--VjPZ6";
export var buttons_two = "calculator-module--buttons_two--qk1oc";
export var calc_type__disksize = "calculator-module--calc_type__disksize--BX+ee";
export var calc_type__inner_buttons = "calculator-module--calc_type__inner_buttons--eFskf";
export var calc_type__wrapper = "calculator-module--calc_type__wrapper--oDrno";
export var calculator = "calculator-module--calculator--4+QfE";
export var calculator__calculate = "calculator-module--calculator__calculate--dV7DI";
export var calculator__container = "calculator-module--calculator__container--cqj5w";
export var calculator__convinced = "calculator-module--calculator__convinced--5+79j";
export var circle = "calculator-module--circle---qhvU";
export var col_one = "calculator-module--col_one--8NM-G";
export var col_two = "calculator-module--col_two--J1Olc";
export var compare = "calculator-module--compare--lJML8";
export var compare_heading = "calculator-module--compare_heading--gESya";
export var conatiner_padd = "calculator-module--conatiner_padd--J6hOK";
export var cylinder = "calculator-module--cylinder--AMpvd";
export var cylinder__blur = "calculator-module--cylinder__blur--MZS3T";
export var cylinder__blur__hover = "calculator-module--cylinder__blur__hover--LmaCj";
export var cylinder__hover = "calculator-module--cylinder__hover--sjndw";
export var cylinder__row = "calculator-module--cylinder__row--NhaFd";
export var cylinder__savings = "calculator-module--cylinder__savings--rVxUw";
export var diskNotation = "calculator-module--diskNotation--Jn1iJ";
export var doller = "calculator-module--doller--W15aL";
export var gbtb = "calculator-module--gbtb--+G1lT";
export var get_started = "calculator-module--get_started--cQOxQ";
export var graph = "calculator-module--graph--cjJCf";
export var graph_heading = "calculator-module--graph_heading--CjPwU";
export var heading = "calculator-module--heading--8CFy5";
export var hero__features = "calculator-module--hero__features--KdbTP";
export var hero__heading = "calculator-module--hero__heading--TZbra";
export var hero__suheading = "calculator-module--hero__suheading--oQ23l";
export var inputbox__wrapper = "calculator-module--inputbox__wrapper---rHiu";
export var labels = "calculator-module--labels--rstTy";
export var labels_container = "calculator-module--labels_container--OK6Te";
export var leaders = "calculator-module--leaders--z8Wjm";
export var left_graph = "calculator-module--left_graph--ftwp4";
export var main_wrapper = "calculator-module--main_wrapper--TWFs5";
export var note = "calculator-module--note--+rhK6";
export var provider = "calculator-module--provider--6zkJ8";
export var readmore = "calculator-module--readmore--65sVF";
export var readmore__more = "calculator-module--readmore__more--IawYW";
export var row = "calculator-module--row--8-DOj";
export var savings_box = "calculator-module--savings_box--5A8yj";
export var savings_box__blur = "calculator-module--savings_box__blur--IbOzE";
export var savings_box__blur1 = "calculator-module--savings_box__blur1--ZQWTD";
export var savings_box__blur1__hover = "calculator-module--savings_box__blur1__hover--CDT3t";
export var savings_box__blur__hover = "calculator-module--savings_box__blur__hover--B+uqQ";
export var savings_box__row = "calculator-module--savings_box__row--cVObR";
export var savings_box__row__first = "calculator-module--savings_box__row__first--Fr8GE";
export var savings_box__row__hover = "calculator-module--savings_box__row__hover--htQe0";
export var size_value = "calculator-module--size_value--E-EWi";
export var slider = "calculator-module--slider--a97Lq";
export var slider_wrapper = "calculator-module--slider_wrapper--8HQ4t";
export var tick = "calculator-module--tick--9Krd+";
export var title = "calculator-module--title--ARFUn";
export var tooltips = "calculator-module--tooltips--ogDwp";
export var top = "calculator-module--top--2c9CY";
export var util_text = "calculator-module--util_text--qi3If";
export var util_wrapper = "calculator-module--util_wrapper--auQnA";
export var values = "calculator-module--values--l+xaw";
export var values__wrapper = "calculator-module--values__wrapper--a78vw";
export var vertical_line = "calculator-module--vertical_line--csPEz";
export var whole_graph = "calculator-module--whole_graph--iwUTF";