import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as classes from "../../../styles/calculator/calculator.module.scss";
import BeforeAfterLucidity from "./BeforeAfterLucidity";
import Graph from "./Graph";
import InputBox from "./InputBox";

const CalculateContainer = () => {
  const result = { diskSpend: 0, downTime: 0, devops: 0 };
  const [removeBlue, setRemoveBlur] = useState(false);
  const [showmore, setShowmore] = useState(false);
  const [providerCost, setProviderCost] = useState(0.08);
  const [calculatedDiskProvision, setCalculatedDiskProvision] = useState(0);
  const [dataInDisk, setDataInDisk] = useState(0);
  const [diskCostPerMonth, setDiskCostPerMonth] = useState({
    before: 0,
    after: 0,
  });
  const [tableVal, setTableVal] = useState({
    projectSpend: [],
    spendPostLucidity: [],
    savings: [],
    cSavings: [],
  });

  const [provider, setProvider] = useState("AWS");
  const [diskType, setDiskType] = useState("spend");
  const [diskSpendOrProvision, setDiskSpendOrProvision] = useState(0);
  const [calculateType, setCalculateType] = useState({ spend: "Yearly" });
  const [diskUtil, setDiskUtil] = useState(25);
  const [dataGrowth, setDataGrowth] = useState(25);
  const [resultsBefore, setResultsBefore] = useState({ ...result });
  const [resultsAfter, setResultsAfter] = useState({ ...result });

  const reset = () => {
    setProvider("AWS");
    setDiskType("spend");
    setDiskSpendOrProvision(0);
    setCalculateType({ spend: "Yearly" });
    setDiskUtil(25);
    setDataGrowth(25);
    setResultsBefore({ ...result });
    setResultsAfter({ ...result });
    setRemoveBlur(false);
    setShowmore(false);
    setProviderCost(0.08);
  };

  const handleProvide = (provider) => {
    setProvider(provider);
    if (provider === "AWS") {
      setProviderCost(0.08);
    } 
    else if(provider === "GCP"){
      setProviderCost(0.05);
    }
    else {
      setProviderCost(0.1);
    }
  };
  const handleDiskType = (type) => {
    if (type === "spend") {
      setCalculateType({ spend: "Yearly" });
    } else {
      setCalculateType({ provisioned: "TB" });
    }
    setDiskType(type);
  };
  const handleDiskNotation = (e) => {
    const type = e.target.name;
    if (type === "GB" || type === "TB") {
      setCalculateType({ provisioned: e.target.name });
    } else {
      setCalculateType({ spend: e.target.name });
    }
  };
  const handleDiskSpendOrProvision = (e) => {
    setDiskSpendOrProvision(Number(e.target.value));
  };
  const handleRange = (e) => {
    if (e.target.name === "util") {
      setDiskUtil(Number(e.target.value));
    } else {
      setDataGrowth(Number(e.target.value));
    }
  };

  const handleMoreDetail = () => {
    setShowmore(!showmore);
  };

  useEffect(() => {
    if (diskType === "spend") {
      // calculate final disk on monthly and yearly
      const finalDiskSpendOrProvision =
        calculateType?.spend === "Yearly"
          ? diskSpendOrProvision / 12
          : diskSpendOrProvision;
      // consider GB -> providerCost = 0.08 or 0.1(aws, azure), finalPov (GB )
      const finalPov = finalDiskSpendOrProvision / providerCost;
      setCalculatedDiskProvision(finalPov);
      setDataInDisk(finalPov * (diskUtil / 100));
    } else {
      setCalculatedDiskProvision(diskSpendOrProvision);
      setDataInDisk(diskSpendOrProvision * (diskUtil / 100));
    }
  }, [diskUtil, diskSpendOrProvision, calculateType]);

  const calculateFinal = () => {
    setRemoveBlur(true);
    // downtime and devops before/after
    // convert provisioned to tb for devops  effort
    const convertToTb = calculateType?.provisioned === "TB" ? calculatedDiskProvision : (calculatedDiskProvision / 1024)
    setResultsBefore((prev) => ({
      ...prev,
      ["downTime"]: Math.ceil(calculatedDiskProvision / (200 * 1024)),
      ["devops"]: Math.round(
        Math.ceil(convertToTb * 12) / 200
      ),
    }));
    setResultsAfter((prev) => ({
      ...prev,
      ["devops"]: Math.round(
        (Math.ceil(convertToTb * 12) / 200 ) * 0.1
      ),
    }));
    //

    if (calculateType?.spend) {
      // spend
      const diskCostBefore =
        calculateType?.spend === "Yearly"
          ? diskSpendOrProvision / 12
          : diskSpendOrProvision;
      setDiskCostPerMonth((prev) => ({
        ...prev,
        before: Math.round(diskCostBefore),
      }));
      setResultsBefore((prev) => ({
        ...prev,
        ["diskSpend"]: Math.round(diskCostBefore),
      }));

      const diskProvAfter = dataInDisk / 0.75;
      const diskCostAfter = diskProvAfter * providerCost;
      setDiskCostPerMonth((prev) => ({
        ...prev,
        after: Math.round(diskCostAfter),
      }));
      setResultsAfter((prev) => ({
        ...prev,
        ["diskSpend"]: Math.round(diskCostAfter),
      }));
    } else {
      // provisioned
      const finalProviderCost =
        calculateType?.provisioned === "TB"
          ? 1024 * providerCost
          : providerCost;
      const diskCostBefore = diskSpendOrProvision * finalProviderCost;
      setDiskCostPerMonth((prev) => ({
        ...prev,
        before: Math.round(diskCostBefore),
      }));
      setResultsBefore((prev) => ({
        ...prev,
        ["diskSpend"]: Math.round(diskCostBefore),
      }));
      const diskProvAfter = dataInDisk / 0.75;
      const diskCostAfter = diskProvAfter * finalProviderCost;
      setDiskCostPerMonth((prev) => ({
        ...prev,
        after: Math.round(diskCostAfter),
      }));
      setResultsAfter((prev) => ({
        ...prev,
        ["diskSpend"]: Math.round(diskCostAfter),
      }));
    }
  };

  const calYearOnYearVal = (arr) => {
    let sum = 0;
    const fdataGrowth = dataGrowth / 100 + 1;
    return arr.map((num, idx) => {
      if (idx === 0) {
        sum = num;
        return num;
      } else {
        sum *= fdataGrowth;
        return Math.round(sum);
      }
    });
  };

  useEffect(() => {
    calculateGraph();
  }, [resultsBefore, resultsAfter]);

  useEffect(() => {
    // provisioned
      const finaldataInDisk =
        calculateType?.provisioned === "TB"
          ? dataInDisk * 1024
          : dataInDisk;
    const lucidityCharge = finaldataInDisk * 0.06 * 12;
    const annualSav =
      tableVal["projectSpend"][0] -
      tableVal["spendPostLucidity"][0] -
      lucidityCharge;
    const annualSavArr = Array(5).fill(Math.round(annualSav));

    const save = calYearOnYearVal(annualSavArr);
    const cumulativeSum = (
      (sum) => (value) =>
        (sum += value)
    )(0);
    setTableVal((prev) => ({
      ...prev,
      ["savings"]: save,
      ["cSavings"]: save.map(cumulativeSum),
    }));
  }, [tableVal.projectSpend, tableVal.spendPostLucidity]);

  // graph calculation
  const calculateGraph = () => {
    if (calculateType !== "Yearly") {
      const projectSpend = Array(5).fill(
        Math.round(diskCostPerMonth.before * 12)
      );
      const spendPostLucidity = Array(5).fill(
        Math.round(diskCostPerMonth.after * 12)
      );
      setTableVal((prev) => ({
        ...prev,
        ["projectSpend"]: calYearOnYearVal(projectSpend),
        ["spendPostLucidity"]: calYearOnYearVal(spendPostLucidity),
      }));
    } else {
      const projectSpend = Array(5).fill(diskCostPerMonth.before);
      const spendPostLucidity = Array(5).fill(diskCostPerMonth.after);
      setTableVal((prev) => ({
        ...prev,
        ["projectSpend"]: calYearOnYearVal(projectSpend),
        ["spendPostLucidity"]: calYearOnYearVal(spendPostLucidity),
      }));
    }
  };

  const props = {
    tableVal,
    removeBlue,
    diskCostPerMonth,
    resultsBefore,
    resultsAfter,
    provider,
    setProvider,
    diskType,
    setDiskType,
    diskSpendOrProvision,
    setDiskSpendOrProvision,
    calculateType,
    setCalculateType,
    diskUtil,
    setDiskUtil,
    dataGrowth,
    setDataGrowth,
    handleProvide,
    handleDiskType,
    handleDiskNotation,
    handleDiskSpendOrProvision,
    handleRange,
    calculateFinal,
    reset,
  };

  return (
    <>
    <section className={classes.calculator__calculate}>
      <Container>
        <div
          className={`${classes.calculator__container} ${
            removeBlue ? classes.conatiner_padd : ""
          }`}
        >
          <Row>
            <Col sm={5} className="ps-md-0 p-0">
              <InputBox
                provider={provider}
                diskType={diskType}
                diskSpendOrProvision={diskSpendOrProvision}
                calculateType={calculateType}
                diskUtil={diskUtil}
                dataGrowth={dataGrowth}
                handleProvide={handleProvide}
                handleDiskType={handleDiskType}
                handleDiskNotation={handleDiskNotation}
                handleDiskSpendOrProvision={handleDiskSpendOrProvision}
                handleRange={handleRange}
                calculateFinal={calculateFinal}
                reset={reset}
              />
            </Col>
            <Col sm={7} className="pe-0 ps-md-4 p-0">
              <BeforeAfterLucidity
                tableVal={tableVal}
                removeBlue={removeBlue}
                diskCostPerMonth={diskCostPerMonth}
                resultsBefore={resultsBefore}
                resultsAfter={resultsAfter}
                diskUtil={diskUtil}
              />
            </Col>
          </Row>
          {removeBlue && (
            <div
              className={`${classes.readmore} ${
                showmore ? classes.readmore__more : ""
              }`}
              onClick={handleMoreDetail}
            >
              <div>
                <span>&#x2303;</span>
                <span>{showmore ? "Less Details" : "More Details"}</span>
              </div>
            </div>
          )}
        </div>
        {showmore && (
          <div className={`${classes.calculator__container}  ${classes.graph}`}>
            <Graph tableVal={tableVal} />
          </div>
        )}
      </Container>
    </section>
    </>
  );
};

export default CalculateContainer;
