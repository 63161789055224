import React from "react";
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import info from "../../../assets/images/calculator/info.svg";
import * as classes from "../../../styles/calculator/calculator.module.scss";
import "../../../styles/calculator/style.scss";

const InputBox = (props) => {
  const {
    provider,
    diskType,
    diskSpendOrProvision,
    calculateType,
    diskUtil,
    dataGrowth,
    handleProvide,
    handleDiskType,
    handleDiskNotation,
    handleDiskSpendOrProvision,
    handleRange,
    calculateFinal,
    reset,
  } = props;

  return (
    <>
    <div className={classes.inputbox__wrapper}>
      <div className={classes.vertical_line}>
        <div className={`${classes.circle} ${classes.top}`}></div>
        <div className={`${classes.circle} ${classes.bottom}`}></div>
      </div>
      <span className={classes.labels}>Choose your cloud provider</span>
      <div className={classes.provider}>
        <Button
          variant="outlined"
          className={`${classes.buttons} ${
            provider === "AWS" ? classes.active : null
          }`}
          onClick={() => handleProvide("AWS")}
        >
          AWS
        </Button>
        <Button
          variant="outlined"
          className={`${classes.buttons} ${
            provider === "Azure" ? classes.active : null
          }`}
          onClick={() => handleProvide("Azure")}
        >
          Azure
        </Button>
        <Button
          variant="outlined"
          className={`${classes.buttons} ${
            provider === "GCP" ? classes.active : null
          }`}
          onClick={() => handleProvide("GCP")}
        >
          GCP
        </Button>
      </div>
      {/*
       *  get size
       */}
      <span className={classes.labels}>
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip>
              <div className={`style_tooltip`}>
                <p>
                  Input your monthly or annual EBS or Azure Disk bill, or input
                  your storage provision in GB or TB
                </p>
              </div>
            </Tooltip>
          }
        >
          <img src={info} alt="info"></img>
        </OverlayTrigger>
        Calculate using
      </span>
      <div className={`${classes.calc_type__wrapper}`}>
        <div className={`${classes.calc_type__inner_buttons}`}>
          <Button
            variant="outlined"
            className={`${classes.buttons} ${
              diskType === "spend" ? classes.active : null
            }`}
            onClick={() => handleDiskType("spend")}
          >
            Disk Spend
          </Button>
          <span>Or</span>
          <Button
            variant="outlined"
            className={`${classes.buttons} ${
              diskType === "provisioned" ? classes.active : null
            }`}
            onClick={() => handleDiskType("provisioned")}
          >
            Disk provisioned
          </Button>
        </div>

        <div className={`${classes.calc_type__disksize}`}>
          <div className={`${classes.size_value}`}>
            <input
              type="number"
              value={diskSpendOrProvision ? diskSpendOrProvision : ""}
              onChange={handleDiskSpendOrProvision}
            ></input>
            <div className={`${classes.diskNotation}`}>
              <span>
                {calculateType?.spend ? "$" : calculateType?.provisioned}
              </span>
            </div>
          </div>
          <div className={classes.gbtb}>
            <Button
              variant="outlined"
              name={diskType === "spend" ? "Yearly" : "TB"}
              className={`${classes.buttons} ${
                calculateType?.provisioned === "TB" ||
                calculateType?.spend === "Yearly"
                  ? classes.active
                  : null
              }`}
              onClick={handleDiskNotation}
            >
              {diskType === "spend" ? "Yearly" : "TB"}
            </Button>
            <Button
              variant="outlined"
              name={diskType === "spend" ? "Monthly" : "GB"}
              className={`${classes.buttons} ${
                calculateType?.provisioned === "GB" ||
                calculateType?.spend === "Monthly"
                  ? classes.active
                  : null
              }`}
              onClick={handleDiskNotation}
            >
              {diskType === "spend" ? "Monthly" : "GB"}
            </Button>
          </div>
        </div>
      </div>

      {/*
       * get utilisation
       */}
      <div className={classes.util_wrapper}>
        <div className={classes.labels_container}>
          <span className={classes.labels}>Disk Utilization</span>
          <span className={classes.labels}>
            <div className={classes.util_text}>
              <img src={info} alt="info"></img>
              {diskUtil < 25 && (
                <p>
                  Significant savings opportunities available as your disk
                  utilization is below industry average of 25%
                </p>
              )}
              {diskUtil > 24 && diskUtil < 46 && (
                <p>
                  Your disk utilization is close to the industry average of 25%,
                  but optimization can lead to greater savings
                </p>
              )}
              {diskUtil > 45 && (
                <p>
                  Great! Your higher-than-average disk utilization suggests key
                  areas for optimization and cost savings.
                </p>
              )}
            </div>
          </span>
        </div>
        <div className={classes.slider}>
          <div className={classes.doller}>
            <span>%</span>
          </div>
          <p>{diskUtil}</p>
          <div className={classes.slider_wrapper}>
            <input
              onChange={handleRange}
              name="util"
              type="range"
              value={diskUtil}
              style={{
                background: `linear-gradient(90deg, #DDFF55 ${diskUtil}%, #707070 ${
                  Number(diskUtil) + 0.1
                }%)`,
              }}
            ></input>
          </div>
        </div>
      </div>

      <div className={classes.util_wrapper}>
        <div className={classes.labels_container}>
          <span className={classes.labels}>Data Growth Rate (Annual)</span>
        </div>
        <div className={classes.slider}>
          <div className={classes.doller}>
            <span>%</span>
          </div>
          <p>{dataGrowth}</p>
          <div className={classes.slider_wrapper}>
            <input
              onChange={handleRange}
              name="data"
              type="range"
              value={dataGrowth}
              style={{
                background: `linear-gradient(90deg, #DDFF55 ${dataGrowth}%, #707070 ${
                  Number(dataGrowth) + 0.1
                }%)`,
              }}
            ></input>
          </div>
        </div>
      </div>

      <div className={classes.action_btns}>
        <Button variant="primary" onClick={calculateFinal}>
          Calculate
        </Button>
        <Button variant="outlined" color="primary" onClick={reset}>
          Reset
        </Button>
      </div>
    </div>
    </>
  );
};

export default InputBox;
