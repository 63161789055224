import React from "react";
import { Col, Row } from "react-bootstrap";
import afterluc from "../../../assets/images/calculator/afterluc.svg";
import beforeluc from "../../../assets/images/calculator/beforeluc.svg";
import * as classes from "../../../styles/calculator/calculator.module.scss";

const BeforeAfterLucidity = (props) => {
  let nf = new Intl.NumberFormat("en-US");
  const {
    tableVal,
    removeBlue,
    diskCostPerMonth,
    resultsBefore,
    resultsAfter,
    diskUtil,
  } = props;

  const saving = Math.round(
    ((diskCostPerMonth?.before - diskCostPerMonth?.after) /
      diskCostPerMonth?.before) *
      100
  );

  return (
    <>
      <div
        className={`${classes.before_after} ${
          removeBlue ? classes.before_after__hover : ""
        }`}
      >
        <div
          className={
            removeBlue
              ? "d-none"
              : `${classes.before_after__blur} ${classes.before_after__blur__hover}`
          }
        >
          <span>Input data to view</span>
        </div>
        <div className={classes.row}>
          <span></span>
          <div className={classes.compare_heading}>
            <div>
              <p>Before Lucidity</p>
            </div>
            <div>
              <p>After Lucidity</p>
            </div>
          </div>
        </div>

        <div className={classes.row}>
          <span>Disk Utilization</span>
          <div className={classes.compare}>
            <div>
              <p>{nf.format(diskUtil)}%</p>
            </div>
            <div>
              <p>75%</p>
            </div>
          </div>
        </div>
        <div className={classes.row}>
          <span>Disk Cost Per month</span>
          <div className={classes.compare}>
            <div>
              <p>$ {nf.format(resultsBefore?.diskSpend)}</p>
            </div>
            <div>
              <p>$ {nf.format(resultsAfter?.diskSpend)}</p>
            </div>
          </div>
        </div>
        <div className={classes.row}>
          <span>Down time per year*</span>
          <div className={classes.compare}>
            <div>
              <p>{nf.format(resultsBefore?.downTime)}</p>
            </div>
            <div>
              <p>{nf.format(resultsAfter?.downTime)}</p>
            </div>
          </div>
        </div>
        <div className={classes.row}>
          <span>DevOps Effort per year (Days)**</span>
          <div className={classes.compare}>
            <div>
              <p>{nf.format(resultsBefore?.devops)}</p>
            </div>
            <div>
              <p>{nf.format(resultsAfter?.devops)}</p>
            </div>
          </div>
        </div>
        <div className={classes.note}>
          <span>
            * Based on 100+ enterprise research, 200 TB managment leads to 1
            Downtime per year
          </span>
          <span>** Management of 100 TB requires 8h in a month</span>
        </div>
      </div>
      {/* cylinder */}
      <div
        className={`${classes.cylinder} ${
          removeBlue ? classes.cylinder__hover : ""
        }`}
      >
        <div
          className={
            removeBlue
              ? "d-none"
              : `${classes.cylinder__blur} ${classes.cylinder__blur__hover}`
          }
        >
          <span>Input data to view</span>
        </div>
        <Row>
          <Col sm={12} className={classes.heading}>
            <h5>Storage Costs</h5>
          </Col>
        </Row>
        <Row className={classes.cylinder__row}>
          <Col sm={4}>
            <div className={classes.before}>
              <span>
                Before <br></br>Lucidity
              </span>
              <div>
                <img src={beforeluc} alt="before lucidity" />
                <p>$ {nf.format(diskCostPerMonth?.before)}</p>
                <p>Per Month</p>
              </div>
            </div>
          </Col>
          <Col sm={8}>
            <div className={classes.after}>
              <span>
                After<br></br> Lucidity
              </span>
              <div>
                <img src={afterluc} alt="after lucidity" />
                <p>$ {nf.format(diskCostPerMonth?.after)}</p>
                <p>Per Month</p>
              </div>
              <div className={classes.cylinder__savings}>
                <h6>{saving}%</h6>
                <span>Savings</span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* savings */}
      <div className={`${classes.savings_box}`}>
        <Row>
          <Col sm={6} className="p-0">
            <div
              className={`${classes.savings_box__row} ${
                classes.savings_box__row__first
              } ${removeBlue ? classes.savings_box__row__hover : ""}`}
            >
              <div
                className={
                  removeBlue
                    ? "d-none"
                    : `${classes.savings_box__blur} ${classes.savings_box__blur__hover}`
                }
              >
                <span>Input data to view</span>
              </div>
              <h6>First Year Savings</h6>
              <h5>$&nbsp;{nf.format(tableVal?.savings[0])}</h5>
            </div>
          </Col>
          <Col sm={6} className="p-0">
            <div
              className={`${classes.savings_box__row} ${
                removeBlue ? classes.savings_box__row__hover : ""
              }`}
            >
              <div
                className={
                  removeBlue
                    ? "d-none"
                    : `${classes.savings_box__blur1} ${classes.savings_box__blur1__hover}`
                }
              >
                <span>Input data to view</span>
              </div>
              <h6>Five Year Savings</h6>
              <h5>$&nbsp;{nf.format(tableVal?.cSavings[4])}</h5>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BeforeAfterLucidity;
